import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { getOptions } from '@bus/options/selectors';
import { RadioGroup } from '@components/common/inputs/RadioGroup';
import { getFieldError } from '@core/FinalForm/getFieldError';
import { styles } from '../styles';
import StyleRadio from '@components/common/uiKit/StyleRadio';

export const RenderStyleField: React.FC<FieldRenderProps<string>> = ({
  input,
  meta,
}) => {
  const options = useSelector(getOptions('style'));

  return (
    <Box sx={styles.radioGroup} display={'flex'} flexDirection={'column'}>
      <Typography variant={'caption'} color={'text.secondary'} mb={'10px'}>
        Style
      </Typography>
      <RadioGroup
        {...input}
        error={!!getFieldError(meta)}
        helperText={getFieldError(meta)?.id}
        data-testid={`visual-adjustments-form-style`}
        values={[...options, { id: 0, name: 'None' }]}
        control={StyleRadio}
        selectedKey={'id'}
      />
    </Box>
  );
};
