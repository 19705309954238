import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { projectsActions } from '../../actions';
import { DeleteChatFileActionPayload } from '../../typedefs';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { profileActions } from '@bus/profile/actions';

export function* deleteChatFile({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteChatFileActionPayload>) {
  try {
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `chats/${payload.chatId}/files/${payload.fileId}` },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(
      projectsActions.removeChatFile({
        chatId: payload.chatId,
        fileId: payload.fileId,
      }),
    );
    yield effects.put(
      profileActions.removeChatFile({
        chatId: payload.chatId,
        fileId: payload.fileId,
      }),
    );
    resolve();
  } catch (e) {
    yield handleError(e);
  }
}
