import React, { FC } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';
import { profileActions } from '@bus/profile/actions';
import { IProjects } from '@bus/projects/typedefs';
import IconContainer from '@components/common/IconContainer';

type DatasetItemProps = {
  name: string;
  description: string;
  project: IProjects;
  isPopular: boolean;
};

export const DatasetItem: FC<DatasetItemProps> = ({
  name,
  description,
  project,
  isPopular,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleCardClick = () => {
    if (!profile?.subscription) {
      dispatch(profileActions.setDatasetsClosed());
      navigate(book.forUnsubscribedUsers);

      return;
    }
    dispatch(profileActions.setCurrentDataset(project));
    dispatch(profileActions.setDatasetsClosed());
    if (project.chats.length) {
      navigate(`${book.chat}/${project.name}/${project.chats[0].id}`);
    } else {
      navigate(`${book.home}`);
    }
  };

  return (
    <Box sx={styles.datasetItem} onClick={handleCardClick}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}>
        <IconContainer
          darkIcon={project.icon_dark}
          lightIcon={project.icon_light}
          size={32}
        />
        <Box display={'flex'} alignItems={'center'}>
          {isPopular && (
            <Box sx={styles.popular}>
              <Typography
                variant={'overline'}
                fontWeight={500}
                letterSpacing={'0.4px'}
                color={'common.success.contrast text'}>
                Popular
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant={isMd ? 'subtitle1' : 'h6'}
          color={'text.primary'}
          fontWeight={500}>
          {name}
        </Typography>
        <Typography variant={'caption'} color={'text.primary'} mt={'8px'}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default DatasetItem;
