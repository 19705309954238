import { ProjectsState } from '@bus/projects/typedefs';
import { ChatState } from '@bus/chat/typedefs';
import { SubscriptionState } from '@bus/subscription/typedefs';
import { PersistState } from '@bus/persist/typedefs';
import { SavedMessagesState } from '@bus/savedMessages/typedefs';
import { SettingsState } from '@bus/settings/typedefs';
import { AiConversationState } from '@bus/aiConversation/typedefs';
import { GuidanceState } from '@bus/guidance/typedefs';
import { CreatedImagesState } from '@bus/createdImages/typedefs';
import { StoryTellerState } from '@bus/storyTeller/typedefs';
import { WsState } from '@bus/ws/typedefs';
// IMPORTS

import React from 'react';

import { AuthState } from '@bus/auth/typedefs';
import { OptionsState } from '@bus/options/typedefs';
import { ProfileState } from '@bus/profile/typedefs';
import { UiState } from '@bus/ui/typedefs';
import { MetametricsState } from '@bus/metametrics/typedefs';
import { ModalState } from '@core/Modal/types';
export enum WSPrefix {
  ws = 'WS',
}

export type RootState = {
  ui: UiState;
  options: OptionsState;
  auth: AuthState;
  profile: ProfileState;
  modal: ModalState;
  projects: ProjectsState;
  chat: ChatState;
  subscription: SubscriptionState;
  persist: PersistState;
  savedMessages: SavedMessagesState;
  settings: SettingsState;
  aiConversation: AiConversationState;
  guidance: GuidanceState;
  createdImages: CreatedImagesState;
  storyTeller: StoryTellerState;
  ws: WsState;
  metametrics: MetametricsState;
  // INJECT
};

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

export enum HttpStatus {
  OK = 200,
  FOUND = 302,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  SERVER_ERROR = 500,
}

export type ServerSideActionPayload<T> = {
  type: string;
  payload: T;
  meta: {
    token: string;
  };
};

export type ServerSideMeta = {
  token?: string;
};

/////////////////////////

export const emptyPaginationState: unknown = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

export interface IOption {
  label: string;
  id: number | string;
}

export type AppRoute<P> = {
  path: string;
  Component: React.ReactElement;
  Layout: React.FC<P>;
  layoutProps: P;
  forRole?: string;
  title?: string;
  exact?: boolean;
};

export type FetchListWorkers = {
  query: string;
  isActive?: boolean;
  myOwn?: boolean;
  status?: boolean;
  categories?: number;
  filter?: string;
};
