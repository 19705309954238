import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { DeleteMetametricsActionPayload } from '@bus/metametrics/typedefs';
import { metametricsActions } from '@bus/metametrics/actions';

export function* deleteMetametrics({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<DeleteMetametricsActionPayload>) {
  try {
    yield effects.put(metametricsActions.startFetching());
    const response: Response = yield effects.apply(api, api.delete, [
      { endpoint: `metametrics/${payload.id}/` },
    ]);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(metametricsActions.removeMetametrics({ id: payload.id }));
    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(metametricsActions.stopFetching());
  }
}
