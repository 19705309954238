import * as effects from 'redux-saga/effects';

import { metametricsActions } from '../actions';
// eslint-disable-next-line prettier/prettier
import {
  fetchMetametrics,
  fetchMetametricsArchive,
  updateMetametrucsStatus,
  deleteMetametrics,
  restoreMetametricsStatus,
  fetchMetametricsCard,
  createChat,
} from './workers';

// IMPORTS
function* watchFetchMetametrics() {
  yield effects.takeEvery(
    metametricsActions.fetchMetametrics.type,
    fetchMetametrics,
  );
}
function* watchFetchMetametricsArchive() {
  yield effects.takeEvery(
    metametricsActions.fetchMetametricsArchive.type,
    fetchMetametricsArchive,
  );
}
function* watchUpdateMetametrucsStatus() {
  yield effects.takeEvery(
    metametricsActions.updateMetametrucsStatus.type,
    updateMetametrucsStatus,
  );
}
function* watchDeleteMetametrics() {
  yield effects.takeEvery(
    metametricsActions.deleteMetametrics.type,
    deleteMetametrics,
  );
}
function* watchRestoreMetametricsStatus() {
  yield effects.takeEvery(
    metametricsActions.restoreMetametricsStatus.type,
    restoreMetametricsStatus,
  );
}
function* watchFetchMetametricsCard() {
  yield effects.takeEvery(
    metametricsActions.fetchMetametricsCard.type,
    fetchMetametricsCard,
  );
}
function* watchCreateChat() {
  yield effects.takeEvery(metametricsActions.createChat.type, createChat);
}
// WATCHERS
export function* watchMetametrics() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    effects.call(watchFetchMetametrics),
    effects.call(watchFetchMetametricsArchive),
    effects.call(watchUpdateMetametrucsStatus),
    effects.call(watchDeleteMetametrics),
    effects.call(watchRestoreMetametricsStatus),
    effects.call(watchFetchMetametricsCard),
    effects.call(watchCreateChat),
    // INJECT
  ]);
}
