import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '@bus/profile/selectors';
import { modalActions } from '@core/Modal/state/actions';
import { LimitExhaustedContent } from '@components/modals/LimitExhausted';
import { SubPlansCode } from '@bus/subscription/typedefs';

type TextType =
  | 'text_line_1'
  | 'text_line_2'
  | 'text_line_3'
  | 'text_line_4'
  | 'text_line_5';

export const useLimits = (
  showUpdatePlanToUseTokensModal: boolean,
  showBuyTokensModal?: boolean | null,
  textLine?: TextType | null,
) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const text: TextType = textLine ?? 'text_line_1';

  const handleLimitExceeded = () => {
    if (showBuyTokensModal) {
      dispatch(
        modalActions.modal({
          component: 'LimitExhausted',
          forceClose: true,
          ...LimitExhaustedContent.buyTokens[text],
          limitText: profile?.subscription?.subscription_plan[text],
          variant: 'secondary',
        }),
      );
    } else if (showUpdatePlanToUseTokensModal) {
      dispatch(
        modalActions.modal({
          component: 'LimitExhausted',
          forceClose: true,
          ...LimitExhaustedContent.updateSubscription,
          limitText: profile?.subscription?.subscription_plan[text],
          variant: 'secondary',
        }),
      );
    } else {
      dispatch(
        modalActions.modal({
          component: 'LimitExceeded',
          title: 'Info',
          forceClose: true,
          text: profile?.subscription?.subscription_plan[text],
          limit: profile?.subscription?.subscription_plan.plan_limit,
          description:
            profile?.subscription?.subscription_plan.code !== SubPlansCode.free
              ? `You have reached the limit of questions for today`
              : `You need to upgrade your subscription plan in order to continue using the chat`,
        }),
      );
    }
  };

  return { handleLimitExceeded };
};
