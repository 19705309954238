import * as effects from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { put } from 'redux-saga/effects';
import { send } from '@giantmachines/redux-websocket';
import { WSPrefix } from '@setup/typedefs';
import { chatActions } from '@bus/chat/actions';
import { wsActions } from '@bus/ws/actions';

export function* sendMessageRequest({
  payload,
  meta: { resolve },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield effects.put(wsActions.setSocketsStatus('SEND'));
    yield effects.put(wsActions.setClosedSockets(false));
    yield effects.put(wsActions.startFetching());
    if (payload.event !== 'visualize' && payload.app === 'chat') {
      yield effects.put(chatActions.startTyping());
    }
    yield put(send(payload, WSPrefix.ws));
    if (payload.event === 'visualize') {
      yield effects.put(chatActions.startIsImageLoading());
    }

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(wsActions.stopFetching());
  }
}
