// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { all, call } from 'redux-saga/effects';

import { watchAuth as authSaga } from '@bus/auth/saga/watchers';
import { watchUi as uiSaga } from '@bus/ui/saga/watchers';
import { watchProjects as projectsSaga } from '@bus/projects/saga/watchers';
import { watchChat as chatSaga } from '@bus/chat/saga/watchers';
import { watchSubscription as subscriptionSaga } from '@bus/subscription/saga/watchers';
import { watchProfile as profileSaga } from '@bus/profile/saga/watchers';
import { watchSavedMessages as savedMessagesSaga } from '@bus/savedMessages/saga/watchers';
import { watchSettings as settingsSaga } from '@bus/settings/saga/watchers';
import { watchAiConversation as aiConversationSaga } from '@bus/aiConversation/saga/watchers';
import { watchGuidance as guidanceSaga } from '@bus/guidance/saga/watchers';
import { watchOptions as optionsSaga } from '@bus/options/saga/watchers';

import { watchCreatedImages as createdImagesSaga } from '@bus/createdImages/saga/watchers';
import { watchStoryTeller as storyTellerSaga } from '@bus/storyTeller/saga/watchers';
import { watchWs as wsSaga } from '@bus/ws/saga/watchers';
import { watchMetametrics as MetametricsSaga } from '@bus/metametrics/saga/watchers';
// IMPORTS

export function* rootSaga() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    call(authSaga),
    call(uiSaga),
    call(projectsSaga),
    call(chatSaga),
    call(subscriptionSaga),
    call(profileSaga),
    call(savedMessagesSaga),
    call(settingsSaga),
    call(aiConversationSaga),
    call(guidanceSaga),
    call(optionsSaga),
    call(createdImagesSaga),
    call(storyTellerSaga),
    call(wsSaga),
    call(MetametricsSaga),
    // INJECT
  ]);
}
