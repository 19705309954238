import { Theme } from '@mui/material/styles';

export const styles = {
  datasetList: (theme: Theme) => ({
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.down(1920)]: {
      flexDirection: 'column',
      gap: '10px',
    },
  }),
  datasetTitle: (theme: Theme) => ({
    [theme.breakpoints.down(1920)]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  }),
  dataSetWrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1920)]: {
      maxWidth: '100%',
      width: '100%',
    },
  }),
  wrapper: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '685px',
    width: '100%',
    [theme.breakpoints.down(1920)]: {
      maxWidth: '100%',
    },
  }),
  item: (theme: Theme) => ({
    [theme.breakpoints.between('lg', 1920)]: {
      maxWidth: '33.3%',
      flexBasis: '33.3%',
    },
  }),
  featureItem: (theme: Theme) => ({
    [theme.breakpoints.between('xl', 1920)]: {
      maxWidth: '16.6%',
      flexBasis: '16.6%',
    },
  }),
  featureMainItem: (theme: Theme) => ({
    [theme.breakpoints.between('lg', 1920)]: {
      maxWidth: '33.3%',
      flexBasis: '33.3%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  }),
};
