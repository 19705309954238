import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Box, CircularProgress, useTheme } from '@mui/material';

import { getIsAuthenticate, getIsInitialize } from '@bus/auth/selectors';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import SuspenseLoading from '@components/common/SuspenseLoading';
import { useRoutersHook } from '@routes/useRoutersHook';
import {
  getIsDatasetsOpen,
  getIsShowProfileTooltip,
  getScaleImage,
} from '@bus/profile/selectors';
import { styles } from './styles';
import { profileActions } from '@bus/profile/actions';
import { IconButton } from '@components/common/uiKit/IconButton';
import { getTooltipId } from '@bus/chat/selectors';
import { chatActions } from '@bus/chat/actions';
import { connect, disconnect } from '@giantmachines/redux-websocket';
import { ratioSplitting } from '@helpers/ratioSplitting';
import Collapse from '@components/icons/Collapse';
import InfoSnackbar from '@components/common/snackbars/InfoSnackbar';
import { SnackbarProvider } from 'notistack';
import { WSPrefix } from '@setup/typedefs';
import useAspectRatio from '@routes/useAspectRatio';
import Share from '@components/pages/private/ImageGalleryPage/Share';
import { getSummary } from '@bus/createdImages/selectors';
import { getAccessToken, removeQuotes } from '@REST/api';
import DeleteOutline from '@components/icons/DeleteOutline';
import { modalActions } from '@core/Modal/state/actions';

const Routers = () => {
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticate);
  const scaleImage = useSelector(getScaleImage);
  const tooltipId = useSelector(getTooltipId);
  const isShowProfileTooltip = useSelector(getIsShowProfileTooltip);
  const summary = useSelector(getSummary);
  const token = getAccessToken();
  const dispatch = useDispatch();
  const [aspectRatio] = useAspectRatio(scaleImage?.image);
  const isDatasetOpen = useSelector(getIsDatasetsOpen);

  const theme = useTheme();
  useRoutersHook();
  const [widthRatio, heightRatio] = ratioSplitting(
    scaleImage?.separator,
    scaleImage?.ratio,
  );

  const closeScale = () => {
    dispatch(profileActions.setScaleImage(null));
  };

  const handleRemoveImage = () => {
    if (scaleImage?.handleDelete) {
      dispatch(
        modalActions.modal({
          component: 'ConfirmOrCancel',
          forceClose: false,
          onCancel: () => {
            scaleImage.handleDelete();
          },
          onConfirm: () => {
            dispatch(modalActions.closeModal('ConfirmOrCancel'));
          },
          modalPayload: {
            title: 'Confirm Action',
            body: 'Are you sure you want to delete this image? You will not be recover it',
          },
          cancelButton: {
            text: 'Delete',
          },
          confirmButton: {
            text: 'Cancel',
          },
        }),
      );
    }
  };

  const handleClose = () => {
    isShowProfileTooltip && dispatch(profileActions.closeProfileTooltip());
    dispatch(chatActions.setTooltipStatus(null));
  };

  const handleCloseMasterMenu = () => {
    dispatch(profileActions.setDatasetsClosed());
  };

  useEffect(() => {
    if (isInitialised) {
      if (isAuthenticated && token) {
        disconnect(WSPrefix.ws);
        dispatch(
          connect(
            `${process.env.REACT_APP_WS_API_URL}/${removeQuotes(token)}`,
            WSPrefix.ws,
          ),
        );
      } else {
        disconnect(WSPrefix.ws);
        dispatch(connect(`${process.env.REACT_APP_WS_API_URL}`, WSPrefix.ws));
      }
    }
  }, [isInitialised, isAuthenticated]);

  useEffect(() => {
    dispatch(profileActions.fetchUserAlive());
  }, []);

  return isInitialised ? (
    <Box sx={styles.notificationWrapper}>
      <SnackbarProvider
        autoHideDuration={2000}
        classes={{ containerRoot: 'z-alert' }}
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        Components={{
          infoSnackbar: InfoSnackbar,
        }}>
        <BrowserRouter basename={'/'}>
          <>
            {scaleImage && (
              <>
                <Box
                  sx={(theme) =>
                    scaleImage.isSwap
                      ? styles.imageWrapperSwap(theme, aspectRatio as number)
                      : styles.imageWrapper(theme, widthRatio, heightRatio)
                  }>
                  <Box
                    component={'img'}
                    src={scaleImage.image}
                    borderRadius={'10px'}
                  />
                  <IconButton
                    sx={styles.closeButton}
                    onClick={closeScale}
                    color={'primary'}>
                    <Collapse
                      sx={{ fontSize: '16px' }}
                      htmlColor={theme.palette.common.white}
                    />
                  </IconButton>
                  {scaleImage.isDelete && (
                    <IconButton
                      sx={styles.deleteButton}
                      onClick={handleRemoveImage}
                      color={'primary'}>
                      <DeleteOutline
                        sx={{ fontSize: '16px' }}
                        htmlColor={theme.palette.common.white}
                      />
                    </IconButton>
                  )}
                  {scaleImage.isShare && (
                    <Share
                      image={scaleImage.image}
                      shortImage={scaleImage.short_image ?? scaleImage.image}
                      summary={scaleImage.isSwap ? '' : summary}
                      type={scaleImage.type}
                      id={scaleImage.id}
                    />
                  )}
                </Box>
                <Box sx={styles.overlay} onClick={closeScale} />
              </>
            )}
            {tooltipId && (
              <Box
                sx={[
                  tooltipId.isBackground
                    ? styles.dark(tooltipId.isOpacityBackground)
                    : styles.withoutBg,
                ]}
                onClick={handleClose}
              />
            )}
            {isDatasetOpen && (
              <Box sx={styles.datasetOverlay} onClick={handleCloseMasterMenu} />
            )}
            <Suspense fallback={<SuspenseLoading />}>
              <Routes>
                {isAuthenticated ? (
                  <Route path={`/*`} element={<PrivateRoutes />} />
                ) : (
                  <Route path={`/*`} element={<PublicRoutes />} />
                )}
              </Routes>
            </Suspense>
          </>
        </BrowserRouter>
      </SnackbarProvider>
    </Box>
  ) : (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}>
      <CircularProgress />
    </Box>
  );
};

export default Routers;
