// @ts-nocheck
import { apply, call } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { WSApp } from '@bus/ws/typedefs';
import { handleResponseChat } from '@bus/ws/saga/workers/handleResponseChat';
import { handleResponseStudio } from '@bus/ws/saga/workers/handleResponseStudio';
import { handleResponseAi } from '@bus/ws/saga/workers/handleResponseAi';
import { handleResponseBilling } from '@bus/ws/saga/workers/handleResponseBilling';
import { handleResponseStoryteller } from '@bus/ws/saga/workers/handleResponseStoryteller';
import { handleResponseMetametrics } from '@bus/ws/saga/workers/handleResponseMetametrics';

export function* handleResponse({ payload }: any) {
  try {
    const data = yield apply(JSON, JSON.parse, [payload.message]);
    if (data.app === WSApp.chat) {
      yield call(handleResponseChat, data);
    }
    if (data.app === WSApp.image_studio) {
      yield call(handleResponseStudio, data);
    }
    if (data.app === WSApp.ai) {
      yield call(handleResponseAi, data);
    }
    if (data.app === WSApp.subscription) {
      yield call(handleResponseBilling, data);
    }
    if (data.app === WSApp.storyteller) {
      yield call(handleResponseStoryteller, data);
    }
    if (data.app === WSApp.metametrics) {
      yield call(handleResponseMetametrics, data);
    }
  } catch (e) {
    yield handleError(e);
  }
}
