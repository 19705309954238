import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { PayloadActionWithPromiseNavigateMeta } from '@core/FinalForm/typedefs';
import { call } from 'redux-saga/effects';
import { metametricsActions } from '@bus/metametrics/actions';
import {
  CreateChatActionPayload,
  UpdateChatActionPayload,
} from '@bus/metametrics/typedefs';
import { projectsActions } from '@bus/projects/actions';
import { book } from '@routes/book';

export function* createChat({
  payload,
  meta: { resolve, reject, navigate },
}: PayloadActionWithPromiseNavigateMeta<CreateChatActionPayload>) {
  try {
    yield effects.put(metametricsActions.startFetching());
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `metametrics/${payload.id}/create-chat/` },
    ]);

    const data: UpdateChatActionPayload = yield call([response, 'json']);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(metametricsActions.addMetametricsChat(data));

    yield effects.put(
      projectsActions.addChat({
        data: data.chat,
        projectId: data.chat.project.id,
      }),
    );

    resolve();
    navigate(`${book.chat}/${data.chat.project.name}/${data.chat.id}`);
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(metametricsActions.stopFetching());
  }
}
