import { Theme } from '@mui/material';

export const styles = {
  appBarTooltip: (theme: Theme) => ({
    width: '310px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderRadius: '8px',
    background: theme.palette.common.surface['surface 4'],
  }),
  iconWrapper: {
    display: 'flex',
    padding: '2px 4px',
    mr: '6px',
    alignItems: 'center',
  },
  switcher: (theme: Theme) => ({
    padding: '4px 12px',
    alignItems: 'center',
    height: '24px',
    '& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
      {
        background: theme.palette.text.primary,
      },
    '& .MuiSwitch-track': {
      background: theme.palette.text.disabled,
      height: '14px',
    },
    '& .MuiButtonBase-root': {
      padding: '2px 9px',
      '& .MuiSwitch-thumb': {
        background: theme.palette.text.primary,
      },
    },
  }),
  tokens: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  mobDivider: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
};
