import * as effects from 'redux-saga/effects';

import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { metametricsActions } from '../../actions';
import { FillArchiveMetametricsActionPayload } from '@bus/metametrics/typedefs';

export function* fetchMetametricsArchive() {
  try {
    yield effects.put(metametricsActions.startFetching());
    const response: Response = yield effects.apply(api, api.get, [
      { endpoint: 'metametrics/archived/' },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
    const data: FillArchiveMetametricsActionPayload = yield effects.call([
      response,
      'json',
    ]);

    yield effects.put(metametricsActions.fillArchiveMetametrics(data));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(metametricsActions.stopFetching());
  }
}
