import * as effects from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';

import { put } from 'redux-saga/effects';
import { send } from '@giantmachines/redux-websocket';
import { WSPrefix } from '@setup/typedefs';
import { createdImagesActions } from '@bus/createdImages/actions';
import { wsActions } from '@bus/ws/actions';

export function* sendImageRequest({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<any>) {
  try {
    yield effects.put(createdImagesActions.startImageStudioLoading());
    yield effects.put(wsActions.setSocketsStatus('SEND'));
    yield effects.put(wsActions.setClosedSockets(false));
    yield effects.put(wsActions.startFetching());
    yield effects.put(createdImagesActions.changeSwap(null));
    resolve();
    yield put(send(payload, WSPrefix.ws));
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(wsActions.stopFetching());
  }
}
