//state type____________________________________
import { ISubscriptionsDetail } from '@bus/profile/typedefs';

export type SubscriptionState = {
  isFetching: boolean;
  isSubscriptionCancelFetching: boolean;
  subscriptionPlans: ISubscriptionPlan[];
  billingStatus: BillingStatus | null;
};

//payload types_________________________________
export type FillSubscriptionPlansActionPayload = ISubscriptionPlan[];
export type SubscriptionsDetailActionPayload = ISubscriptionsDetail;
export type CreateSubscriptionActionPayload = {
  paypal_subscription_id: number;
};
// INJECT

//common types__________________________________
export interface ISubscriptionPlan {
  id: number;
  code: SubPlansCode;
  name: string;
  price_title: string;
  header: null | string;
  text_line_1: null | string;
  text_line_2: null | string;
  text_line_3: null | string;
  text_line_4: null | string;
  text_line_5: null | string;
  interval_unit: string;
  plan_limit: number;
  plan_visualize_limit: number;
  plan_metametric_limit: number;
  plan_visualize_minute_limit: number;
  plan_storyteller_prompt_limit: number;
  plan_storyteller_prompt_image_limit: number;
  plan_storyteller_limit: number;
  price: string;
  paypal_plan_id: null | string;
  created_at: string;
}

export enum BillingStatus {
  processing = 'processing',
  error = 'error',
  success = 'success',
}

export enum SubPlansCode {
  free = 'free',
  basicAnnual = 'basic_annual',
  premiumAnnual = 'premium_annual',
  ultimateAnnual = 'ultimate_annual',
  basicMonthly = 'basic_monthly',
  premiumMonthly = 'premium_monthly',
  ultimateMonthly = 'ultimate_monthly',
}
