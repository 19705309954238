// @ts-nocheck
import * as effects from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { fetchSettings } from '@bus/settings/saga/workers';
import { subscriptionsDetail } from '@bus/subscription/saga/workers';
import { IResponseChatMessage, WSAction, WSEvent } from '@bus/ws/typedefs';
import { metametricsActions } from '@bus/metametrics/actions';
import { book } from '@routes/book';
import { getNavigate } from '@helpers/navigateGlobal';

export function* handleResponseMetametrics(payload: IResponseChatMessage) {
  try {
    const navigate = getNavigate();

    if (
      payload.action === WSAction.notification &&
      payload.event === WSEvent.create &&
      payload.data.action === 'start'
    ) {
      yield effects.put(
        metametricsActions.addMetametrics(payload.data.metametric),
      );
      navigate(book.metametrics);
      yield effects.put(metametricsActions.stopSending());
      yield call(fetchSettings);
      yield effects.call(subscriptionsDetail);
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.create
    ) {
      yield effects.put(
        metametricsActions.updateMetametrics(payload.data.metametric),
      );
    }
    if (payload.action === WSAction.error && payload.event === WSEvent.create) {
      yield effects.put(
        metametricsActions.updateMetametrics(payload.data.metametric),
      );
      yield call(fetchSettings);
      yield effects.call(subscriptionsDetail);
    }
  } catch (e) {
    yield handleError(e);
  }
}
