// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  DeleteMetametricsActionPayload,
  FillArchiveMetametricsActionPayload,
  FillMetametricsActionPayload,
  FillMetametricsCardActionPayload,
  MetametricsState,
  RestoreMetametricsActionPayload,
  UpdateChatActionPayload,
  UpdateMetametricsActionPayload,
} from './typedefs';

const initialState: MetametricsState = {
  isFetching: false,
  isSend: false,
  activeMetametrics: [],
  archiveMetametrics: [],
  metametricsCard: null,
};

const metametricsSlice = createSlice({
  name: 'metametrics',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startSending(state) {
      state.isSend = true;
    },
    stopSending(state) {
      state.isSend = false;
    },
    fillActiveMetametrics(
      state,
      action: PayloadAction<FillMetametricsActionPayload>,
    ) {
      state.activeMetametrics = action.payload;
    },
    fillArchiveMetametrics(
      state,
      action: PayloadAction<FillArchiveMetametricsActionPayload>,
    ) {
      state.archiveMetametrics = action.payload;
    },
    fillMetametricsCard(
      state,
      action: PayloadAction<FillMetametricsCardActionPayload>,
    ) {
      state.metametricsCard = action.payload;
    },
    removeMetametrics(
      state,
      action: PayloadAction<DeleteMetametricsActionPayload>,
    ) {
      state.activeMetametrics = state.activeMetametrics.filter(
        (item) => item.id !== action.payload.id,
      );
      state.archiveMetametrics = state.archiveMetametrics.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    changeMetametrics(
      state,
      action: PayloadAction<UpdateMetametricsActionPayload>,
    ) {
      state.activeMetametrics = state.activeMetametrics.filter(
        (item) => item.id !== action.payload.id,
      );
      state.archiveMetametrics = [...state.archiveMetametrics, action.payload];
    },
    changeArchiveMetametrics(
      state,
      action: PayloadAction<RestoreMetametricsActionPayload>,
    ) {
      state.archiveMetametrics = state.archiveMetametrics.filter(
        (item) => item.id !== action.payload.id,
      );
    },
    addMetametrics(
      state,
      action: PayloadAction<UpdateMetametricsActionPayload>,
    ) {
      const inArray = state.activeMetametrics.some(
        (item) => item.id === action.payload.id,
      );

      const results = state.activeMetametrics.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });

      state.activeMetametrics = inArray
        ? results
        : [...state.activeMetametrics, action.payload];
    },
    updateMetametrics(
      state,
      action: PayloadAction<UpdateMetametricsActionPayload>,
    ) {
      state.activeMetametrics = state.activeMetametrics.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });
    },
    addMetametricsChat(state, action: PayloadAction<UpdateChatActionPayload>) {
      state.activeMetametrics = state.activeMetametrics.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });
    },
    // INJECT
  },
});

export default metametricsSlice;
