import { createAction } from '@reduxjs/toolkit';

import projectsSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const projectsActions = {
  ...projectsSlice.actions,
  fetchProjects: createAction('projects/fetchProjects'),
  postRate: createAction('projects/postRate', prepareActions.movePromiseToMeta),
  readGuidance: createAction('projects/readGuidance', (payload: any) => {
    return { payload };
  }),
  createNewChat: createAction(
    'projects/createNewChat',
    prepareActions.withPromiseNavigate,
  ),
  updateChat: createAction(
    'projects/updateChat',
    prepareActions.movePromiseToMeta,
  ),
  deleteChat: createAction(
    'projects/deleteChat',
    prepareActions.movePromiseToMeta,
  ),
  fetchMessageAudio: createAction(
    'projects/fetchMessageAudio',
    prepareActions.movePromiseToMeta,
  ),
  deleteChatFile: createAction(
    'projects/deleteChatFile',
    prepareActions.movePromiseToMeta,
  ),
  fetchVisualizePrompt: createAction(
    'projects/fetchVisualizePrompt',
    (payload: any) => {
      return { payload };
    },
  ),
  fetchAutoName: createAction('projects/fetchAutoName', ({ chatId }) => ({
    payload: { chatId },
  })),
  createNewMessageChat: createAction(
    'projects/createNewMessageChat',
    prepareActions.withNavigate,
  ),
  projectMatching: createAction(
    'projects/projectMatching',
    ({ query, project_id }) => ({
      payload: { query, project_id },
    }),
  ),
  fetchSuggestingQuestions: createAction(
    'projects/fetchSuggestingQuestions',
    ({ chatId }) => ({
      payload: { chatId },
    }),
  ),
  // INJECT
};
