import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import wsSlice from './slice';
import { WSPrefix } from '@setup/typedefs';

export const wsActions = {
  ...wsSlice.actions,
  sendMessageRequest: createAction(
    'ws/sendMessageRequest',
    ({ values, resolve }) => {
      return {
        payload: values,
        meta: { resolve },
      };
    },
  ),
  sendImageRequest: createAction(
    'ws/sendImageRequest',
    prepareActions.movePromiseToMeta,
  ),
  handleResponse: createAction(`${WSPrefix.ws}::MESSAGE`, (payload) => ({
    payload,
  })),
  // INJECT
};
