import * as effects from 'redux-saga/effects';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { PayloadActionWithPromiseMeta } from '@core/FinalForm/typedefs';
import { call } from 'redux-saga/effects';
import { metametricsActions } from '@bus/metametrics/actions';
import {
  UpdateMetametricsActionPayload,
  UpdateMetametrucsStatusActionPayload,
} from '@bus/metametrics/typedefs';

export function* updateMetametrucsStatus({
  payload,
  meta: { resolve, reject },
}: PayloadActionWithPromiseMeta<UpdateMetametrucsStatusActionPayload>) {
  try {
    yield effects.put(metametricsActions.startFetching());
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: `metametrics/${payload.id}/archive/` },
    ]);

    const data: UpdateMetametricsActionPayload = yield call([response, 'json']);
    if (!response.ok) {
      reject();
      yield throwError(response);
    }

    yield effects.put(metametricsActions.changeMetametrics(data));

    resolve();
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(metametricsActions.stopFetching());
  }
}
