import React, { FC } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { styles } from './styles';
import IconContainer from '@components/common/IconContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '@bus/profile/selectors';
import { book } from '@routes/book';
import { profileActions } from '@bus/profile/actions';

type FeatureItemProps = {
  iconDark: string;
  iconLight: string;
  name: string;
  link: string;
  handleClick: () => void;
  description: string;
  isBeta?: boolean;
};

export const FeatureItem: FC<FeatureItemProps> = ({
  iconDark,
  iconLight,
  name,
  link,
  handleClick,
  description,
  isBeta = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const desk = useMediaQuery(theme.breakpoints.between('xl', 1920));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const handleCardClick = () => {
    if (!profile?.subscription) {
      navigate(book.forUnsubscribedUsers);

      return;
    }
    dispatch(profileActions.setDatasetsClosed());
    handleClick();
    navigate(link);
  };

  return (
    <Box sx={styles.featureItem} onClick={handleCardClick}>
      <Box sx={styles.wrapper}>
        <IconContainer darkIcon={iconDark} lightIcon={iconLight} size={32} />
        {isBeta && (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}>
            <Box sx={styles.beta}>
              <Typography
                variant={'overline'}
                fontWeight={500}
                letterSpacing={'0.4px'}
                color={'common.warning.contrast text'}>
                BETA
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant={desk || isMd ? 'subtitle1' : 'h6'}
          fontWeight={500}
          color={'text.primary'}>
          {name}
        </Typography>
        <Typography variant={'caption'} color={'text.primary'} mt={'8px'}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default FeatureItem;
