// @ts-nocheck
import { handleError } from '@bus/ui/saga/workers/handleError';
import * as effects from 'redux-saga/effects';
import { aiConversationActions } from '@bus/aiConversation/actions';
import { getIsAudioPlay } from '@bus/aiConversation/selectors';
import { IResponseAiMessage } from '@bus/ws/typedefs';

export function* aiMessage(payload: IResponseAiMessage) {
  const { data } = payload;
  const isAudioLivePlaying = yield effects.select(getIsAudioPlay);
  window.localStorage.removeItem('isSessionProcessing');
  const settings = window.localStorage?.getItem('isSessionCompleted');
  let isSessionCompleted = null;
  if (settings) {
    isSessionCompleted = JSON.parse(settings);
  }
  try {
    if (data.is_last_message) {
      yield effects.put(
        aiConversationActions.setLastMessageSessionId(data.session_id),
      );
    }
    const message = {
      id: data.message_id,
      created_at: data.created_at || '',
      type: data.message_type,
      text: data.text || null,
      images: data.images,
      audios: data.audios || [],
      isLive: isAudioLivePlaying,
      dataset: {
        id: data.dataset_id,
        project: {
          description: data.project_description,
          id: data.project_id,
          name: data.project_name,
        },
      },
    };

    if (data.message_type === 'image') {
      yield effects.put(
        aiConversationActions.addMessageImage({
          message,
          id: data.session_id,
          isLive: !data.is_last_message,
          isPlaceholder: message.images.some((item) => item.image === null),
        }),
      );
    } else {
      yield effects.put(
        aiConversationActions.addMessage({
          message,
          id: data.session_id,
          isLive: !data.is_last_message,
        }),
      );
    }

    if (isSessionCompleted) {
      yield effects.put(aiConversationActions.nextSession());

      yield effects.put(
        aiConversationActions.setLastMessageSessionId(data.session_id),
      );

      yield effects.put(
        aiConversationActions.finishSession({
          id: data.session_id,
          isCompleted: true,
        }),
      );
      window.localStorage.removeItem('isSessionCompleted');
    }
  } catch (e) {
    yield handleError(e);
  }
}
