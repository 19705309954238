import React from 'react';

import { Box } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { CreateSubscriptionActions } from '@paypal/paypal-js';
import { subscriptionActions } from '@bus/subscription/actions';
import { BillingStatus, SubPlansCode } from '@bus/subscription/typedefs';
import { profileActions } from '@bus/profile/actions';
import { handleErrors } from '@helpers/handleErrors';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, getSubscriptionId } from '@bus/profile/selectors';
import { settingsActions } from '@bus/settings/actions';
import { ActionLogsEvent, ActionLogsType } from '@bus/settings/typedefs';
import {
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types/components/buttons';

type SubscribeProps = {
  planId: string;
};

export const Subscribe: React.FC<
  ModalTypes.ModalComponentProps<SubscribeProps>
> = ({ planId }) => {
  const profile = useSelector(getProfile);
  const subscriptionId = useSelector(getSubscriptionId);
  const dispatch = useDispatch();
  const onCreateSubscribe = (
    data: Record<string, unknown>,
    actions: CreateSubscriptionActions,
  ) => {
    if (
      profile?.subscription &&
      profile?.subscription.subscription_plan.code !== SubPlansCode.free
    ) {
      return actions.subscription
        .revise(subscriptionId, {
          plan_id: planId,
        })
        .then((data) => {
          dispatch(
            settingsActions.actionLogs({
              type: ActionLogsType.info,
              event: ActionLogsEvent.subscription,
              text: JSON.stringify(data),
              action: 'Create Revise',
            }),
          );

          return data;
        })
        .catch((error) => {
          dispatch(
            settingsActions.actionLogs({
              type: ActionLogsType.error,
              event: ActionLogsEvent.subscription,
              text: JSON.stringify(error),
              action: 'Create Revise',
            }),
          );

          return error;
        });
    }

    return actions.subscription
      .create({
        plan_id: planId,
        custom_id: profile?.uuid,
      })
      .then((subscriptionID) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.info,
            event: ActionLogsEvent.subscription,
            text: JSON.stringify(subscriptionID),
            action: 'Create Subscription',
          }),
        );

        return subscriptionID;
      })
      .catch((error) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.error,
            event: ActionLogsEvent.subscription,
            text: JSON.stringify(error),
            action: 'Create Subscription',
          }),
        );

        return error;
      });
  };

  const onApprove = (data: OnApproveData, actions: OnApproveActions) => {
    return actions
      .subscription!.get()
      .then((data) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.info,
            event: ActionLogsEvent.subscription,
            text: JSON.stringify(data),
            action: 'Approve',
          }),
        );
        dispatch(
          subscriptionActions.setBillingStatus(BillingStatus.processing),
        );

        new Promise((resolve, reject) => {
          dispatch(
            profileActions.updateProfile({
              values: { paypal_payment_status: BillingStatus.processing },
              resolve,
              reject,
            }),
          );
        })
          .then()
          .catch();

        return data;
      })
      .catch((error) => {
        dispatch(
          settingsActions.actionLogs({
            type: ActionLogsType.error,
            event: ActionLogsEvent.subscription,
            text: JSON.stringify(error),
            action: 'Approve',
          }),
        );

        return error;
      });
  };

  const onError = (err: Record<string, unknown>) => {
    dispatch(
      settingsActions.actionLogs({
        type: ActionLogsType.error,
        event: ActionLogsEvent.subscription,
        text: JSON.stringify(err),
        action: 'Error',
      }),
    );
    handleErrors(err);
  };

  return (
    <Box sx={styles.subscribe}>
      <Box width={'100%'} sx={styles.buttonWrapper}>
        <PayPalButtons
          style={{
            shape: 'pill',
            label: 'subscribe',
          }}
          createSubscription={onCreateSubscribe}
          onApprove={onApprove as any}
          onError={onError}
        />
      </Box>
    </Box>
  );
};

export default Subscribe;
