import { Theme } from '@mui/material/styles';

export const styles = {
  appBar: (theme: Theme) => ({
    position: 'fixed',
    zIndex: 9998,
    width: '100%',
    top: 0,
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    px: '20px',
    background: theme.palette.common.surface['surface 5'],
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  loader: (theme: Theme) => ({
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    '& .MuiLinearProgress-root': {
      backgroundColor: theme.palette.action.active,
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  actions: {
    display: 'flex',
    gap: '8px',
    '& button': {
      height: '49px',
      padding: '16px 24px',
    },
  },
  wrapper: {
    position: 'relative',
    ml: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  menuWrapper: (theme: Theme) => ({
    // position: 'absolute',
    // right: 0,
    // zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    // padding: '0 50px 0 7px',
    borderRadius: '16px',
    border: `1px solid ${theme.palette.divider}`,
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  }),
  tokens: (theme: Theme) => ({
    display: 'flex',
    gap: '5px',
    height: '100%',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '16px 0 0 16px',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: theme.palette.common.action['hover 2'],
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  profile: (theme: Theme) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    background: theme.palette.warning.dark,
  }),
  buttonWrapper: (theme: Theme, isNoToken: boolean) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: isNoToken ? '16px' : '0 16px 16px 0',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      background: isNoToken ? 'none' : theme.palette.common.action['hover 2'],
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '16px',
    },
  }),
  boxButton: (theme: Theme) => ({
    cursor: 'pointer',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: theme.palette.common.action['normal 2'],
    borderRadius: '28px',
    padding: '8px 8px 8px 10px',
  }),
  button: (theme: Theme) => ({
    position: 'relative',
    zIndex: 2,
    width: '24px',
    height: '24px',
    background: theme.palette.common.action['normal 2'],
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
  active: (theme: Theme) => ({
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  }),
  wrapperProjects: (theme: Theme) => ({
    background: 'rgba(0, 0, 0, 0.4)',
    opacity: 0,
    visibility: 'hidden',
    transform: 'translateY(-100%)',
    mt: '80px',
    position: 'fixed',
    zIndex: 1000,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    '& > div': {
      background: theme.palette.common.surface['surface 5'],
      pb: '20px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      maxHeight: 'calc(100vh - 80px)',
      '& > div': {
        padding: '0 20px',
        flex: '1 1 auto',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.action.disabled,
          borderRadius: '10px',
        },
      },
    },
  }),
  open: {
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)',
  },
  margin: (theme: Theme) => ({
    mt: '20px',
    [theme.breakpoints.down('xl')]: {
      mt: '10px',
    },
  }),
};
